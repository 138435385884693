<template>
  <div
    @click.stop="hidden(false)"
    v-if="showModal"
    class="file-import-box"
    id="import-file-box"
  >
    <div v-if="spinning" class="loading-box">
      <a-spin
        class="loading-child"
        tip="Loading..."
        :spinning="spinning"
      >
      </a-spin>
    </div>
    <div v-else @click.stop="hidden(true)" class="file-import-center-writer">
      <div class="file-import-administrator-header">
        <!-- 导入 -->
        <div
          v-if="pageShowActive == pageActiveObj.ImportActive"
          class="administrator-header-main"
        >
          {{ getString(strings.Mind_Import) }}
        </div>
        <!-- 扫描创建 -->
        <div v-else class="administrator-header-main">
          {{ getString(strings.Mind_Scan_Creation) }}
        </div>
        <div class="administrator-header-right">
          <img
            class="hidden-icon close-button-img"
            style="margin-top: 5px"
            @click.stop="hidden(false)"
            src="../../assets/img/popupsImg/colse_new.svg"
            alt=""
          />
        </div>
      </div>
      <section class="file-import-content" ref="importFileBox">
        <!-- 目前支持导入的文件类型 -->
        <h2 v-if="pageShowActive == pageActiveObj.ImportActive" class="supported-file-types">
          {{ getString(strings.Supported_Imported_File_Types) }}
        </h2>
        <!-- 目前支持扫描的文件类型 -->
        <h2 v-else class="supported-file-types">
          {{ getString(strings.Supported_Scan_File_Types) }}
        </h2>
        <div v-if="historyList.length > 0"
          class="historyList-menulist-each-box">
          <div
            v-for="(item, index) in historyList"
            :key="index"
            @click="openFileItem(item)"
            class="historyList-menulist-each-list"
          >
            <div class="historycell-menulist-each-title">
              <img
                  class="history_local-cloud-icon"
                  v-if="item.isCloud" 
                  src="../../assets/img/user/local_cloud_icon.svg"
                  alt=""
                  title="已同步"
                />
              <span class="historycell-menulist-each-title-span">
                {{ item.name }}
              </span>
              <span class="historycell-menulist-each-title-time">
                {{ timestampToTimeDHM(item.modifyTime) }}
              </span>
            </div>
            <div class="historycell-path-label">
              {{ item.path }}
            </div>
          </div>
        </div>

          <img v-if="historyList.length == 0"
            class="import-icon"
            src="../../assets/img/fileImport/import.svg"
            alt=""
          />
          <!-- 直接拖入文件，即可上传 -->
          <p class="drag-file-upload" v-if="historyList.length == 0">
            {{ getString(strings.Drag_The_File_Directly_To_Upload) }}
          </p>
          <div
            class="available-times-box"
            v-if="pageShowActive == pageActiveObj.OcrScanActive"
          >
            <!-- ocr扫描可用次数 -->
            <section class="available-times-title" v-if="ocrScanAvailableTimes">
              {{ getString(strings.Mind_Available_Times) }}：{{
                ocrScanAvailableTimes.scanLeft
              }}/{{ ocrScanAvailableTimes.scanTotal }}
            </section>          
            <img
              class="upgrade-member-img"
              src="../../assets/images/ocr/upgrade_member.svg"
              @click="upgradeMember"
            />
          </div>
          
          <div :class="historyList.length > 0 ? 'import-btn-right' : 'import-btn'"> 
            <!-- 添加文件  -->
            <label for="file">
              {{ getString(strings.Mind_Edit_Select_locally) }}
            </label>
          </div>
          <input
            type="file"
            style="display: none"
            id="file"
            accept=".xjmm, .xmind, .md, .txt, .docx, .png, .jpg, .webp, .jpeg"
            @change="fileInfo()"
          />
      </section>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {} from "../../common/netWork/group_api";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import timestampToTime from "../../utils/timestampToTime";
import SaveMindFile from "../../core/core/dataformat/SaveMindFile";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import { eventBusMethod } from "../../assets/js/GlobalVariable.js";
import { postMindmapListDir, postMindmapOcrInfo } from "../../common/netWork/mind_map_api";
import XJMindFIleImport from '../../core/core/dataformat/fileImport/XJMindFIleImport';
import MindMapZipConstant from '../../viewmodel/core/tools/mapfile/MindMapZipConstant';
import HistoryForMyMindData from '../../viewmodel/core/tools/mapfile/data/HistoryForMyMindData';
import LoadLocalXJMind from '../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
}
const pageActiveObj = {
  ImportActive: "importActive", //导入
  OcrScanActive: "ocrScanActive", //ocr扫描
};

export default {
  inject: ["reload"],
  components: {},
  props: {
    show: {
      type: Boolean,
      defualt() {
        return false;
      },
    },
  },
  data() {
    return {
      strings,
      showModal: false,
      fileName: "", //文件名
      spinning: false, //是否展示加载动画
      pageActiveObj,
      pageShowActive: pageActiveObj.ImportActive,
      ocrScanAvailableTimes: null, //ocr扫描可用次数
      historyList:[],
    };
  },
  created() {
  },
  updated() {
  },
  mounted() {
    this.bus.$on(eventBusMethod.ScanCreationEvent, (val) => {
      // 打开扫描创建
      this.showModal = true;
      this.pageShowActive = pageActiveObj.OcrScanActive;
      this.getOcrScanAvailableTimes();
    });
  },
  beforeUpdate() {
    //每次打开弹窗执行
  },
  methods: {
    ...mapMutations(["isShowShoppingModel"]),
    getString(i) {
      return getString(i);
    },
    httpImageToPrefix(src) {
      //拼接图片地址
      return httpImageToPrefix(src);
    },

    refreshHistory() {
      if (this.$tools.isElectron()) {
        let that = this;
        LoadLocalXJMind.appPath = this.$router.appPath
        new XJMindFIleImport(null, null, this.$router.appPath).loadHistoryData().then((historyData) => {
          if (historyData != null && historyData.list != null) {
            that.historyList = []
            for (let index = 0; index < historyData.list.length; index++) {
              const element = historyData.list[index];
              let cell = new HistoryForMyMindData()
              cell.name = element.name
              cell.modifyTime = Math.ceil(element.time / 1000)
              cell.path = element.path
              if (element.path != null && element.path.indexOf(MindMapZipConstant.MindZIPNewPathe) > -1) {
                cell.path = "未保存文件"
              }
              cell.localpath = element.localpath;
              cell.id = element.id != null ? element.id : index + "-local";
              cell.mindMapId = index + "-local";
              cell.isHistory = true
              that.historyList.push(cell)
            }
            // postMindmapListDir(
            //     { parentId: "" },
            //     (res) => {
            //       let entities = res.entities
            //       for (let index = 0; index < that.historyList.list.length; index++) {
            //         const element = that.historyList.list[index];
            //         element.isCloud = false
            //         for (let j = 0; j < entities.length; j++) {
            //           if (entities[j].mindMapId == element.id && element.id != null && element.id.length > 10) {
            //             element.isCloud = true
            //             break;
            //           }
            //         }
            //       }
                  
            //     },
            //     (error) => {
            //     },
            //     () => {
            //     }
            //   );
          }
        })
      }
    },

    openFileItem(item) {
      let path = item.path
      if (item.path ==  "未保存文件") {
        path = MindMapZipConstant.MindZIPNewPathe
      } else {
        var fs = window.require('fs');
        if (!fs.existsSync(path)) {
          new XJMindFIleImport(null, null, this.$router.appPath).removeHistory(path, item.localpath, item.name)
          this.$message.error("文件不存在");
          return
        }
      }
      if (item.id != null && item.id.length > 10 && item.id.indexOf("-local") == -1) {
          ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(path) +
                          "&localpath=" + encodeURIComponent(item.localpath) + 
                          "&id=" + item.id + 
                          "&t=" + item.modifyTime);
      } else {
          ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(path) +
                          "&localpath=" + encodeURIComponent(item.localpath));
      }
      new XJMindFIleImport(null, null, this.$router.appPath).addHistory(path, item.localpath, item.name, item.id)
    },

    timestampToTimeDHM(timestamp) {
      return timestampToTime.formatTime(timestamp);
    },
    //本地文件上传
    fileInfo() {
      // 获取input标签选择的文件,并选择第一条
      let resultFile = document.getElementById("file").files[0];
      // 如果文件存在
      if (resultFile) {
        // 获取文件名
        this.fileName = resultFile.name;
        this.fileImport(resultFile);
      }
    },
    //拖拽上传
    onDrag(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      let resultFile = e.dataTransfer.files[0];
      if (resultFile) {
        this.fileImport(resultFile);
      }
    },
    fileImport(file) {
      // if(file.size > 1024 * 1024 *3) {
      //     this.$message.error('文件大小不能超过3M!');
      //     return false;
      // }
      this.spinning = true;
      new SaveMindFile(this).openMindFile(file);
    },
    //隐藏模态框
    hidden(show) {
      this.showModal = show;
      if (show == false) {
        let item = {
          show: false,
        };
        this.$emit("hiddenFileImportModal", item);
        let resultFile = document.getElementById("file");
        if (resultFile) {
          resultFile.value = "";
        }
      }
    },
    upgradeMember() {
      //升级会员
      this.isShowShoppingModel(true);
    },
    getOcrScanAvailableTimes() {
      //ocr扫描可用次数
      postMindmapOcrInfo(
        "",
        (res) => {
          if (res) {
            this.ocrScanAvailableTimes = res;
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
  },
  watch: {
    show(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.refreshHistory()
        this.pageShowActive = pageActiveObj.ImportActive;
        this.$nextTick(() => {
          let upload = this.$refs.importFileBox;
          if (upload) {
            upload.addEventListener("dragenter", this.onDrag, false);
            upload.addEventListener("dragover", this.onDrag, false);
            upload.addEventListener("drop", this.onDrop, false);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.file-import-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  cursor: pointer;
  
  .historyList-menulist-each-box {
    position: absolute;
    left: 0;
    top: 0;
    right: 20px;
    left: 30px;
    top: 130px;
    height: 170px;
    
    overflow: auto;
  }
  .historyList-menulist-each-list {
    left: 0;
    top: 0;
    height: 45px;
    border-bottom: 1px solid #e1e1e1;
  }
  .historycell-menulist-each-title {
    display:flex;
    font-weight: 700;
    left: 10px;
    font-size: 14px;
    color: #161616;
    align-items: normal;
    align-content: start;
    width: 100%;
    margin-top: 5px;
  }
  .historycell-menulist-each-title-span {
    font-size: 14px;
    color: #161616;
    align-items: normal;
    align-content: start;
    cursor: pointer;
  }
  .historycell-menulist-each-title-time {
    font-size: 12px;
    color: #666666;
    align-items: normal;
    align-content: end;
    position: absolute;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
  }
  .history_local-cloud-icon {
    width: 14px;
    height: 14px;
  }
  .historycell-path-label {
    left: 10px;
    font-size: 12px;
    color: #333;
    display:flex;
    text-decoration:underline;
    margin-top: -3px;
    cursor: pointer;
  }
  /* 加载动画开始 */
  .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 3000;
  }
  .loading-child {
    z-index: 3000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  .ant-spin-dot-item {
    background-color: #fd492b;
  }
  .ant-spin {
    color: #fd492b;
  }
  .ant-spin .ant-spin-dot-item {
    background-color: #fd492b !important;
  }
  /* 加载动画结束 */

  .file-import-center-writer {
    width: 610px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 6px;
    cursor: auto;
    .file-import-administrator-header {
      background: #efefef;
      display: flex;
      padding: 6px 12px 6px 20px;
      border-radius: 6px 6px 0 0;
      .administrator-header-left {
        flex: 0 0 100px;
        font-size: 14px;
      }

      .administrator-header-main {
        flex: 1;
        width: 100%;
        // text-align: center;
        color: #333;
        font-size: 14px;
        font-weight: 550;
        line-height: 24px;
      }

      .administrator-header-right {
        flex: 0 0 100px;
        text-align: right;
        font-size: 12px;
        img {
          margin-right: 0px;
        }
        .hidden-icon {
          cursor: pointer;
        }
      }
    }
    .close-modal {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      font-size: 16px;
      color: #333;
      border-radius: 0 10px 0 0;
    }
    .file-import-content {
      width: 570px;
      height: 308px;
      margin: 20px;
      padding: 30px;
      background: #f1f1f1;
      border: 1px solid #e2e2e2;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .supported-file-types {
        width: 90%;
        height: 25px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 25px;
        color: #333333;
        margin-bottom: 0;
        margin-top: -15px;
      }
      .import-icon {
        width: 64px;
        margin-top: 48px;
      }
      .drag-file-upload {
        width: 154px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
        margin-top: 10px;
        margin-bottom: 0;
      }
      .available-times-box {
        display: flex;
        margin-top: 10px;
        .available-times-title {
          font-size: 14px;
          color: #666666;
        }
        .upgrade-member-img {
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .import-btn {
        width: 104px;
        height: 36px;
        line-height: 36px;
        background: #fd492b;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 20px;
        label {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
        &:hover {
          background: #ff7354;
        }
      }
      .import-btn-right {
        width: 104px;
        height: 36px;
        line-height: 36px;
        background: #fd492b;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 20px;
        bottom: 30px;
        position: absolute;

        label {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
        &:hover {
          background: #ff7354;
        }
      }
    }
  }
}
.close-button-img {
  width: 18px;
  cursor: pointer;
  opacity: 0.5;
}
.close-button-img:hover {
  opacity: 0.8;
}
</style>